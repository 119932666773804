import axios from 'axios';
import useService from '../common/useService';
import { MdiCase } from './mdi-case.model';

const useSyncPrescriptions = () => useService(({ id }: { id: string }) => axios.get(`mdi/cases/${id}`));
const useSyncMdiCase = () => useService(({ id }: { id: string }) => axios.get(`mdi/sync/prescriptions/${id}`));

const useSyncCase = () => useService(({ id }: { id: string }) => axios.get<MdiCase>(`mdi/sync/${id}`));

const useUpdateCaseLockId = () =>
  useService(({ ...body }: { caseId: string }) => axios.get(`mdi/update-lock-id/${body.caseId}`));

const MdiService = {
  useSyncPrescriptions,
  useSyncCase,
  useSyncMdiCase,
  useUpdateCaseLockId,
};

export default MdiService;
