const downloadBlobAsCsv = (blob: Blob, filename: string) => {
  const url = URL.createObjectURL(blob);

  const pom = document.createElement('a');
  pom.href = url;
  pom.setAttribute('download', filename);
  pom.click();
};

const FileHelpers = {
  downloadBlobAsCsv,
};

export default FileHelpers;
