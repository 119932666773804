import { useEffect } from 'react';
import { BtnPrimary, Form, Page, Table, TableRow } from '../common/ui-component';
import ProductService from '../product/product.service';
import { withDefault } from '../utils/remote-data';
import { Product } from '../product/product.entity';
import { ConnectedForm, DatePickerInput, DropdownInput, MultipleDropdownInput } from '../common/form-controller';
import { useTranslation } from 'react-i18next';
import ProductRecommendationService, { Params, RecommendationResult, Rule } from './product-recommendation.service';
import RemoteDataContent from '../common/ui-component/layouts/RemoteDataContent.v2';
import MultipleDropdownInputV2 from '../common/form-controller/MultipleDropdownInput.v2';
import { Alert, TableBody, TableCell, TableHead } from '@mui/material';
import Section from '../common/ui-component/layouts/Section.v2';

export default function TesterPage() {
  const [getProducts, rProducts] = ProductService.useGetProducts();
  const [getRules, rRules] = ProductRecommendationService.useGetRules();
  const [getRecommendation, rRecommentations] = ProductRecommendationService.useGetProductRecommendation();
  useEffect(() => {
    getProducts();
    getRules();
  }, [getProducts, getRules]);

  const { t } = useTranslation('product_recommendation');
  return (
    <>
      <Page.Content>
        <Section>
          <Section.Title>{t('productRecommendation')}</Section.Title>
          <Form.Section title="">
            <ConnectedForm<Params>
              onSubmit={(form) => getRecommendation(form)}
              defaultValues={{ dateOfBirth: null, userChosenPids: [], userPrefenceMethods: [], gender: null }}
            >
              <Form.Section.Grid>
                <Form.Section.Row>
                  <Form.Section.Row.Field md={6}>
                    <DropdownInput
                      name="gender"
                      toOption={(x) => ({ displayText: x, id: x, value: x })}
                      label={t('gender')}
                      items={['Male', 'Female']}
                    />
                  </Form.Section.Row.Field>
                  <Form.Section.Row.Field md={6}>
                    <DatePickerInput name="dateOfBirth" label={t('dateOfBirth')} required={false} />
                  </Form.Section.Row.Field>
                  <Form.Section.Row.Field md={6}>
                    <MultipleDropdownInput<string>
                      name="userPrefenceMethods"
                      toOption={(x) => ({ displayText: x, id: x, value: x })}
                      label={t('userPrefenceMethods')}
                      items={['Oral', 'Topical']}
                    ></MultipleDropdownInput>
                  </Form.Section.Row.Field>
                  <Form.Section.Row.Field md={6}>
                    <MultipleDropdownInputV2<Product, number>
                      name="userChosenPids"
                      toOption={(x) => ({ displayText: x.sku, id: x.sku, value: x.wooCommerceId })}
                      label={t('userChosenPids')}
                      items={withDefault<Product[]>([])(rProducts)}
                    ></MultipleDropdownInputV2>
                  </Form.Section.Row.Field>
                </Form.Section.Row>
                <Form.Section.Row md={4}>
                  <BtnPrimary type="submit">{t('send')}</BtnPrimary>
                </Form.Section.Row>
              </Form.Section.Grid>
              <Section.Sub title={t('recommendation')}>
                <div style={{ minHeight: '150px' }}>
                  <RemoteDataContent value={rRecommentations}>
                    <RemoteDataContent.Success>
                      {(recommendations: RecommendationResult) =>
                        recommendations.length ? (
                          <>
                            <ol type="1">
                              {recommendations.map((products, i) => (
                                <li key={i}>{products.map((x) => x.sku).join(', ')}</li>
                              ))}
                            </ol>
                          </>
                        ) : (
                          <>
                            <Alert severity="warning">No Recommendations for this Parameters</Alert>
                          </>
                        )
                      }
                    </RemoteDataContent.Success>
                    <RemoteDataContent.NotAsked>
                      <>{t('notAsked')}</>
                    </RemoteDataContent.NotAsked>
                    <RemoteDataContent.Failure>
                      {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                      {(value: any) => {
                        return (
                          <>
                            <ul>
                              {'response' in value && value?.response?.data.message.map((x: string) => <li>{x}</li>)}
                            </ul>
                          </>
                        );
                      }}
                    </RemoteDataContent.Failure>
                  </RemoteDataContent>
                </div>
              </Section.Sub>
            </ConnectedForm>
          </Form.Section>
        </Section>

        <Section>
          <Section.Title>Rules</Section.Title>
          <RemoteDataContent value={rRules}>
            <RemoteDataContent.Success>
              {(rules: Rule[]) => (
                <Table>
                  <TableHead>
                    <TableCell>Priority</TableCell>
                    <TableCell>Gender</TableCell>
                    <TableCell>Over50</TableCell>
                    <TableCell>User Preference Methods</TableCell>
                    <TableCell>User Chosen Product</TableCell>
                    <TableCell>Recommendation</TableCell>
                  </TableHead>
                  <TableBody>
                    <>
                      {rules.map((x, i) => (
                        <TableRow>
                          <TableCell>{i}</TableCell>
                          <TableCell>{x.gender.toString()}</TableCell>
                          <TableCell>{x.over50.toString()}</TableCell>
                          <TableCell>{x.userPreferenceMethods}</TableCell>
                          <TableCell>{x.userChosenMethods}</TableCell>
                          <TableCell>
                            {x.products.map((product) => (product.length > 1 ? 'BUNDLE' : product[0])).join(' | ')}
                          </TableCell>
                        </TableRow>
                      ))}
                    </>
                  </TableBody>
                </Table>
              )}
            </RemoteDataContent.Success>
          </RemoteDataContent>
        </Section>
      </Page.Content>
    </>
  );
}
