import { Controller, useFormContext } from 'react-hook-form';
import MultipleDropdownFieldV2 from '../ui-component/inputs/MultipleDropdownField.v2';

interface Props<T, V> {
  name: string;
  label: string;
  type?: string;
  readOnly?: boolean;
  required?: boolean;
  items: T[];
  toOption: (t: T) => { displayText: string; value: V; id: string };
}

const MultipleDropdownInputV2 = <T, V>({ label, name, readOnly, required, items: options, toOption }: Props<T, V>) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => {
        return (
          <MultipleDropdownFieldV2
            label={label}
            onChange={onChange}
            values={value == null ? [] : value}
            items={options}
            readonly={readOnly}
            toOption={toOption}
            required={required}
          />
        );
      }}
    />
  );
};

export default MultipleDropdownInputV2;
