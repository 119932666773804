import { Alert, Divider, Snackbar } from '@mui/material';
import { DateTime } from 'luxon';
import { ConnectedForm, DatePickerInput, DropdownInput, TextInput } from '../common/form-controller';
import { BtnDanger, BtnPrimary, FormSection, Icons, Link, Section, SectionContent } from '../common/ui-component';
import RemoteDataContent from '../common/ui-component/layouts/RemoteDataContent';
import { MdiService } from '../mdi';
import { isSuccess, loading, notAsked } from '../utils/remote-data';
import CaseService from './orders.service';
import MedicalCaseService from '../medical-case/medical-case.service';
import { ConfirmRoutedModal } from '../common/ui-component/modals';
import { CASE_ROUTE } from '../Router';
import { isAdminUser } from '../authentication/auth';
import { useAccessToken } from '../authentication';
import MarketingPlaformService from '../marketing-platform/marketing-platform.service';
import { _databaseDateOnlyFormat } from '../utils/date/helper';
import { FileHelpers } from '../utils/file';

const hashCreateEncounterForHUDD = '#health-update-due-date-trigger';
const hashAddToHealthUpdateList = '#add-health-update-due-date-list-trigger';
const CaseFixer = () => {
  const currentRoute = CASE_ROUTE;
  const [assignCase, result, setAssignAs] = CaseService.useAssignCase();
  const [regenerateCase, result2, setRegenAs] = CaseService.useRegenerateCase();

  const [updateCaseFromMdi, caseUpdateResult, setUpdateCaseAs] = MdiService.useSyncPrescriptions();

  const [triggerHealthUpdate, triggerResult, setTriggerReuslt] = MedicalCaseService.useTriggerHealthUpdates();

  const [triggerHealthUpdateList, triggerHealthUpdateListResult, setTriggerHealthUpdateListResult] =
    MarketingPlaformService.useTriggerHealthUpdateList();

  const [getCallList, callListResult, setCallList] = MedicalCaseService.useGetAnnualHealthUpdateCallList();
  const [user] = useAccessToken();

  return (
    <>
      {isAdminUser(user) && (
        <>
          <Section title={'Set Case id'}>
            <SectionContent>
              <p>The order id should be the parent order id, use carefully pls</p>
            </SectionContent>

            <ConnectedForm
              defaultValues={{ caseId: '', orderId: '', status: undefined }}
              onSubmit={(v) => {
                setAssignAs(loading());
                assignCase(v);
              }}
            >
              <FormSection title={''}>
                <TextInput label="orderId" name="orderId"></TextInput>
                <TextInput label="caseId" name="caseId"></TextInput>
                <DropdownInput
                  label="Status"
                  items={[undefined, 'approved', 'in-review']}
                  name="status"
                  toOption={(t) => ({
                    id: t || '',
                    displayText: t || 'No Status',
                    value: t,
                  })}
                />
                <BtnPrimary type="submit">Send Case Id</BtnPrimary>
              </FormSection>
            </ConnectedForm>
          </Section>
          <Divider />
        </>
      )}
      <Section title="Try to send to pharmacy">
        <SectionContent>
          <p>
            Check if the order id status has change to pharmacy-order-sent, if not maybe its parent order has not been
            approved or order prescription doesn't exists or its already been shipped. Send us the order id and the
            reason pls
          </p>
        </SectionContent>

        <ConnectedForm
          defaultValues={{ caseId: '', orderId: '' }}
          onSubmit={(v) => {
            setRegenAs(loading());
            regenerateCase({ id: v.orderId });
          }}
        >
          <FormSection title={''}>
            <TextInput label="orderId" name="orderId"></TextInput>
            <BtnPrimary type="submit">Send To Pharmacy</BtnPrimary>
          </FormSection>
        </ConnectedForm>
      </Section>
      <Divider />
      <Section title="Retrieve case from MDI">
        <SectionContent>
          <p>Retrieve case, case's prescriptions from MDI then update the backoffice's database.</p>
        </SectionContent>

        <ConnectedForm
          defaultValues={{ caseId: '' }}
          onSubmit={(v) => {
            setUpdateCaseAs(loading());
            updateCaseFromMdi({ id: v.caseId });
          }}
        >
          <FormSection title={''}>
            <TextInput label="caseId" name="caseId"></TextInput>
            <BtnPrimary type="submit">Send To MDI</BtnPrimary>
          </FormSection>
        </ConnectedForm>
      </Section>
      <Divider />
      <Section title="Annual Health Update Call List">
        <SectionContent>
          <p>Create and download a CSV list of phone numbers for customers whose HUDD is 20 days from selected day.</p>
        </SectionContent>

        <ConnectedForm
          defaultValues={{ date: '' }}
          onSubmit={async (v) => {
            setCallList(loading());
            const formattedDate = DateTime.fromISO(v.date).toFormat(_databaseDateOnlyFormat);
            const blob = await getCallList(formattedDate);
            if (!isSuccess(blob)) return;
            FileHelpers.downloadBlobAsCsv(blob.value, `call-list-generated-on_${formattedDate}.csv`);
          }}
        >
          <FormSection title={''}>
            <DatePickerInput required name="date" label="Date" />
            <BtnPrimary type="submit">Download Call List</BtnPrimary>
          </FormSection>
        </ConnectedForm>
      </Section>
      {isAdminUser(user) && (
        <>
          <Section title="Trigger create case for all health update due date for today">
            <Divider>
              <BtnDanger>
                <Link to={`${currentRoute}/${hashCreateEncounterForHUDD}`}>
                  <>
                    TRIGGER CREATE ENCOUNTER FOR HUDD FOR TODAY
                    <Icons.Bomb />
                  </>
                </Link>
              </BtnDanger>
            </Divider>
          </Section>
          <Section title="Trigger add to health update due list">
            <Divider>
              <BtnDanger>
                <Link to={`${currentRoute}/${hashAddToHealthUpdateList}`}>
                  <>
                    TRIGGER ADD TO HEALTH UPDATE DUE LIST
                    <Icons.Bomb />
                  </>
                </Link>
              </BtnDanger>
            </Divider>
          </Section>
        </>
      )}
      <RemoteDataContent
        value={result}
        whenSuccess={() => (
          <Snackbar open anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
            <Alert
              variant="filled"
              severity="success"
              onClick={() => {
                setAssignAs(notAsked());
              }}
            >
              {'success -> click on me '}
            </Alert>
          </Snackbar>
        )}
        whenNotAsked={() => <></>}
      />
      <RemoteDataContent
        value={result2}
        whenSuccess={() => (
          <Snackbar open anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
            <Alert
              variant="filled"
              severity="success"
              onClick={() => {
                setRegenAs(notAsked());
              }}
            >
              {'success -> click on me '}
            </Alert>
          </Snackbar>
        )}
        whenNotAsked={() => <></>}
      />
      <RemoteDataContent
        value={caseUpdateResult}
        whenSuccess={() => (
          <Snackbar open anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
            <Alert
              variant="filled"
              severity="success"
              onClick={() => {
                setUpdateCaseAs(notAsked());
              }}
            >
              {'success -> click on me '}
            </Alert>
          </Snackbar>
        )}
        whenNotAsked={() => <></>}
      />
      <ConfirmRoutedModal
        predicatedHash={hashCreateEncounterForHUDD}
        defaultUrl={currentRoute}
        title={'Are you sure you want trigger case for all health update due today  ?'}
        value={'a'}
        onConfirm={(_, close) => {
          triggerHealthUpdate();
          close();
        }}
      ></ConfirmRoutedModal>
      <ConfirmRoutedModal
        predicatedHash={hashAddToHealthUpdateList}
        defaultUrl={currentRoute}
        title={'Are you sure you want trigger add to update due list ?'}
        value={'a'}
        onConfirm={(_, close) => {
          triggerHealthUpdateList();
          close();
        }}
      ></ConfirmRoutedModal>
      <RemoteDataContent
        value={triggerResult}
        whenSuccess={() => (
          <Snackbar open anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
            <Alert
              variant="filled"
              severity="success"
              onClick={() => {
                setTriggerReuslt(notAsked());
              }}
            >
              {'success -> click on me '}
            </Alert>
          </Snackbar>
        )}
        whenNotAsked={() => <></>}
      />
      <RemoteDataContent
        value={triggerHealthUpdateListResult}
        whenSuccess={() => (
          <Snackbar open anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
            <Alert
              variant="filled"
              severity="success"
              onClick={() => {
                setTriggerHealthUpdateListResult(notAsked());
              }}
            >
              {'success -> click on me '}
            </Alert>
          </Snackbar>
        )}
        whenNotAsked={() => <></>}
      />
      <RemoteDataContent
        value={callListResult}
        whenSuccess={() => {
          return (
            <Snackbar open anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
              <Alert
                variant="filled"
                severity="success"
                onClick={() => {
                  setCallList(notAsked());
                }}
              >
                {'success -> click on me '}
              </Alert>
            </Snackbar>
          );
        }}
        whenNotAsked={() => <></>}
      />
    </>
  );
};

export default CaseFixer;
