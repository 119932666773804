import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { AddressContent } from '../common/address';
import {
  BtnPrimary,
  Dropdown,
  ErrorHandler,
  FormRow,
  Info,
  Link,
  Page,
  SectionContent,
  StatusHistoryList,
  SuccessRemoteDataToast,
  Title,
} from '../common/ui-component';
import RemoteDataContent from '../common/ui-component/layouts/RemoteDataContent.v2';
import Section from '../common/ui-component/layouts/Section.v2';
import { ConfirmRoutedModal, RoutedModal } from '../common/ui-component/modals';
import SuccessToast from '../common/ui-component/toasts/SuccessToast';
import { TooltipsV2 as Tooltips } from '../common/ui-component/tooltips';
import { PHARMACY_ORDER_ROUTE } from '../Router';
import { isSuccess, notAsked } from '../utils/remote-data';
import { CancellableResendableError, getTrackingUrl, PharmacyNameEnum, PharmacyOrder } from './pharmacy-order.model';
import PharmacyValidator from './pharmacy-order.validator';
import PharmacyService from './pharmacy.service';
import { ReplacementForm } from './replacement';
import { resendOrderHash } from './SelectActionOptions';
import { ShippingForm } from './shipping-form';
import ResendForm from './ResendForm';
import StatusChip from './StatusChip';
import { PharmacyOrderHistory } from '../models';
import { BtnUnstyled } from '../common/ui-component/buttons';
import ChemistryrxService from './chemistryrx/chemistryrx.service';
import { ChemistryrxOrder } from './chemistryrx/chemistryrx-order.model';
import CommentList from '../common/ui-component/CommentList';
import { ConnectedForm, TextInput } from '../common/form-controller';
import { CommentDto } from './chemistryrx/comment.dto';
import axios from 'axios';
import { Alert } from '@mui/material';

export const replaceOrderHash = '#replace';
export const cancelOrderHash = '#cancel';
export const shippingOrderHash = '#shipping';

const PharmacyErrors = ({ errors }: { errors: string[] }) => {
  const { t } = useTranslation('pharmacy');

  return errors.length ? (
    <Section.Sub>
      <Section.Title>{t('pharmacyErrors')}</Section.Title>
      <SectionContent>
        <div>
          {errors.map((error) => (
            <div style={{ color: 'red' }} key={error}>
              {error}
            </div>
          ))}
        </div>
      </SectionContent>
    </Section.Sub>
  ) : (
    <></>
  );
};

const DetailPage = () => {
  const { id } = useParams();
  const currentRoute = `${PHARMACY_ORDER_ROUTE}/${id}`;
  const { t } = useTranslation('pharmacy');

  const [fetchOrder, rOrder] = PharmacyService.useGetOrder();
  const [fetchChemistryrxOrder, rChemistryrxOrder] = ChemistryrxService.useGetOrder();
  const [comment, rComment] = ChemistryrxService.useComment();
  const [getHistory, rHistories] = PharmacyService.useGetPharmacyOrderHistory();

  const [cancelPharmacyOrder, rCancelPharmacyOrder, setCancelPharmacyOrder] = PharmacyService.useCancelOrder();

  const [showSuccess, toggleSuccess] = useState(false);

  const onSubmit = useCallback(
    async (commentDto: CommentDto) => {
      const o = await comment(commentDto);
      if (isSuccess(o)) {
        const { orderId, version } = o.value;

        fetchChemistryrxOrder({ orderId, version });
      }
    },
    [comment, fetchChemistryrxOrder],
  );

  useEffect(() => {
    (async () => {
      if (id) {
        const o = await fetchOrder(+id);
        getHistory(+id);

        if (isSuccess(o)) {
          const { orderId, version, pharmacyName } = o.value;
          if (pharmacyName === PharmacyNameEnum.CHEMISTRY_RX) {
            fetchChemistryrxOrder({ orderId, version });
          }
        }
      }
    })();
  }, [fetchOrder, fetchChemistryrxOrder, getHistory, id]);

  return (
    <Page.Content>
      <RemoteDataContent value={rOrder}>
        <RemoteDataContent.Success>
          {(order: PharmacyOrder) => {
            const isResendable =
              !order.hasAlreadyBeenShipped &&
              order.status === 'error' &&
              CancellableResendableError.some((x) => x === order.statusDetails);
            return (
              <>
                <Page.Content.Split>
                  <Page.Content.Split.Main>
                    <Section key={`${order.pharmacyName} | ${order.orderId}-${order.version}`}>
                      <Section.Title>
                        <Title>
                          {`${order.firstName} ${order.lastName} - ${order.pharmacyName} - ${t('orderId')} #${
                            order.orderId
                          }-${order.version} `}
                          <StatusChip status={order.status} />
                        </Title>
                      </Section.Title>

                      <PharmacyErrors errors={PharmacyValidator.getAllErrors(order, t)} />
                      <Section.Sub title={t('detail')}>
                        <SectionContent>
                          <Info label={t('orderId')}>
                            <Link to={`/orders/${order.orderId}`}>{order.orderId}</Link>
                          </Info>
                          <Info label={t('created')}>{order.created || ''}</Info>
                          <Info label={t('updated')}>{order.updated || ''}</Info>
                        </SectionContent>
                      </Section.Sub>
                      <Section.Sub title={t('shipping')}>
                        <SectionContent>
                          <Info label={t('method')}>{order.shippingMethod || ''}</Info>
                          <Info label={t('trackingNumber')}>
                            {!order.trackingNumber ? (
                              '--'
                            ) : (
                              <Link to={getTrackingUrl(order.carrier, order.trackingNumber)}>
                                {order.trackingNumber}
                              </Link>
                            )}
                          </Info>
                        </SectionContent>
                      </Section.Sub>
                      <Section.Sub title={t('address')}>
                        <AddressContent
                          address={{
                            address1: order.overriddenAddress?.address1 || order.address1,
                            address2: order.overriddenAddress?.address2 || order.address2,
                            city: order.overriddenAddress?.city || order.city,
                            country: order.overriddenAddress?.country || order.country,
                            phone: order.overriddenAddress?.phone || order.phone,
                            postcode: order.overriddenAddress?.zip || order.zip,
                            state: order.overriddenAddress?.state || order.state,
                          }}
                        />
                      </Section.Sub>
                      <Section.Sub title={t('product')}>
                        <>
                          {order.rxItems.map((item) => (
                            <SectionContent key={item.sku}>
                              <Info label={t('sku')}>{item.sku || ''}</Info>
                              <Info label={t('medicationName')}>{item.prescription?.medicationName || ''}</Info>
                              <Info label={t('prescriber')}>{item.prescription?.prescribingDoctor || ''}</Info>
                            </SectionContent>
                          ))}
                          {order.otcItems.map((item) => (
                            <SectionContent key={item.sku}>
                              <Info label={t('sku')}>{item.sku || ''}</Info>
                              <Info label={t('medicationName')}>{item.name || ''}</Info>
                              <Info label={t('quantity')}>{item.quantity || ''}</Info>
                            </SectionContent>
                          ))}
                        </>
                      </Section.Sub>
                      <Section.Sub title={t('clinician')}>
                        <SectionContent>
                          <Info label={t('firstName')}>{order.clinician?.firstName || ''}</Info>
                          <Info label={t('lastName')}>{order.clinician?.lastName || ''}</Info>
                        </SectionContent>
                      </Section.Sub>
                    </Section>
                  </Page.Content.Split.Main>
                  <Page.Content.Split.Side>
                    <Section>
                      <Section.Title>{t('actions')}</Section.Title>
                      <Dropdown>
                        <Dropdown.Item>
                          <Tooltips>
                            <Tooltips.Conditional predicate={!isResendable}>
                              <span>{t('resendTooltip')}</span>
                            </Tooltips.Conditional>
                            <Tooltips.Content>
                              <BtnUnstyled disabled={!isResendable}>
                                <Link to={resendOrderHash} replace>
                                  {t('resend')}
                                </Link>
                              </BtnUnstyled>
                            </Tooltips.Content>
                          </Tooltips>
                        </Dropdown.Item>

                        <Dropdown.Item>
                          <Tooltips>
                            <Tooltips.Conditional predicate={!order.hasAlreadyBeenShipped}>
                              <span>{t('replaceTooltip')}</span>
                            </Tooltips.Conditional>
                            <Tooltips.Content>
                              <BtnUnstyled disabled={!order.hasAlreadyBeenShipped}>
                                <Link to={replaceOrderHash} replace>
                                  {t('replace')}
                                </Link>
                              </BtnUnstyled>
                            </Tooltips.Content>
                          </Tooltips>
                        </Dropdown.Item>

                        <Dropdown.Item>
                          <Tooltips>
                            <Tooltips.Conditional predicate={order.status === 'out_for_delivery'}>
                              <span>We can only Change Shipping before is out for delivery</span>
                            </Tooltips.Conditional>
                            <Tooltips.Content>
                              <BtnUnstyled disabled={order.status === 'out_for_delivery'}>
                                <Link to={shippingOrderHash} replace>
                                  {t('changeShipping')}
                                </Link>
                              </BtnUnstyled>
                            </Tooltips.Content>
                          </Tooltips>
                        </Dropdown.Item>

                        <Dropdown.Item>
                          <Tooltips>
                            <Tooltips.Conditional predicate={order.status === 'out_for_delivery'}>
                              <span>We can only cancel before is out for delivery</span>
                            </Tooltips.Conditional>
                            <Tooltips.Content>
                              <BtnUnstyled disabled={order.status === 'out_for_delivery'}>
                                <Link to={cancelOrderHash} replace>
                                  {t('cancel')}
                                </Link>
                              </BtnUnstyled>
                            </Tooltips.Content>
                          </Tooltips>
                        </Dropdown.Item>
                      </Dropdown>
                    </Section>
                    <Section>
                      <Section.Title>{t('history')}</Section.Title>
                      <RemoteDataContent value={rHistories}>
                        <RemoteDataContent.Success>
                          {(history: PharmacyOrderHistory[]) => (
                            <StatusHistoryList
                              history={history.flatMap((h, i) => {
                                if (h.new.status === h.old?.status) return [];
                                return [
                                  {
                                    created: h.created,
                                    id: i,
                                    source: '',
                                    status: h.new.status,
                                  },
                                ];
                              })}
                            />
                          )}
                        </RemoteDataContent.Success>
                      </RemoteDataContent>
                    </Section>
                    {order.pharmacyName === PharmacyNameEnum.CHEMISTRY_RX ? (
                      <Section>
                        <Section.Title>{t('messages')}</Section.Title>

                        <RemoteDataContent value={rChemistryrxOrder}>
                          <RemoteDataContent.Success>
                            {(co: ChemistryrxOrder) => (
                              <div style={{ width: '100%' }}>
                                <RemoteDataContent<ChemistryrxOrder> value={rComment}>
                                  <RemoteDataContent.Failure>
                                    {(f: unknown) => {
                                      if (axios.isAxiosError(f)) {
                                        switch (f.response?.status) {
                                          case 500:
                                            return <>{<Alert severity="error">{t('sftpError')}</Alert>}</>;
                                          case 404:
                                            return <>{<Alert severity="error">{t('notFoundError')}</Alert>}</>;
                                        }
                                      }
                                      return <ErrorHandler err={f} />;
                                    }}
                                  </RemoteDataContent.Failure>
                                  <RemoteDataContent.NotAsked>
                                    <></>
                                  </RemoteDataContent.NotAsked>
                                </RemoteDataContent>
                                <ConnectedForm
                                  defaultValues={{
                                    orderId: co.orderId,
                                    version: co.version,
                                    text: '',
                                  }}
                                  onSubmit={(formValue) => onSubmit(formValue)}
                                >
                                  <FormRow>
                                    <TextInput name="text" label={t('comment')} />
                                  </FormRow>

                                  <BtnPrimary sx={{ m: 0 }} type="submit">
                                    {t('send')}
                                  </BtnPrimary>
                                </ConnectedForm>
                                {co.comments.length && <CommentList comments={co.comments} />}
                              </div>
                            )}
                          </RemoteDataContent.Success>
                        </RemoteDataContent>
                      </Section>
                    ) : (
                      <></>
                    )}
                  </Page.Content.Split.Side>
                </Page.Content.Split>

                <SuccessRemoteDataToast
                  value={rCancelPharmacyOrder}
                  onClick={() => {
                    setCancelPharmacyOrder(notAsked());
                  }}
                />
                {showSuccess ? <SuccessToast onClick={() => toggleSuccess(false)} /> : <></>}

                <RoutedModal predicatedHash={shippingOrderHash} defaultUrl={currentRoute} title={t('shipping')}>
                  {(onClose: () => void) => <ShippingForm pharmacyOrder={order} onSuccess={onClose} close={onClose} />}
                </RoutedModal>
                <RoutedModal predicatedHash={replaceOrderHash} defaultUrl={currentRoute} title={t('replacement')}>
                  {(onClose: () => void) => (
                    <ReplacementForm pharmacyOrder={order} onSuccess={onClose} close={onClose} />
                  )}
                </RoutedModal>

                <RoutedModal predicatedHash={resendOrderHash} defaultUrl={currentRoute} title={t('resend')}>
                  {(onClose: () => void) => <ResendForm pharmacyOrder={order} onSuccess={onClose} close={onClose} />}
                </RoutedModal>
                <ConfirmRoutedModal
                  predicatedHash={cancelOrderHash}
                  defaultUrl={currentRoute}
                  title={t('cancel')}
                  value={order}
                  onConfirm={(value, close) => {
                    cancelPharmacyOrder(value.id);
                    close();
                  }}
                ></ConfirmRoutedModal>
              </>
            );
          }}
        </RemoteDataContent.Success>
      </RemoteDataContent>
    </Page.Content>
  );
};

export default DetailPage;
