import axios from 'axios';
import useService from '../../common/useService';
import { ChemistryrxOrder } from './chemistryrx-order.model';
import { CommentDto } from './comment.dto';

const useGetOrder = () =>
  useService(({ orderId, version }: { orderId: number; version: number }) =>
    axios.get<ChemistryrxOrder>(`/chemistryrx/${orderId}/${version}`),
  );
const useComment = () =>
  useService((commentDto: CommentDto) =>
    axios.post<ChemistryrxOrder>(`/chemistryrx/comment/${commentDto.orderId}/${commentDto.version}`, commentDto),
  );

const ChemistryrxService = {
  useGetOrder,
  useComment,
};

export default ChemistryrxService;
