const PHARMACY_EN = {
  actions: 'Actions',
  address: 'Address',
  address1: 'Address 1',
  address2: 'Address 2',
  approved: 'Approved',
  carrier: 'Carrier',
  city: 'City',
  clinicianAssigned: 'Has Clinician Assigned',
  clinician: 'Clinician',
  close: 'Close',
  country: 'Country',
  created: 'Created',
  createdWithTime: 'Created (with time)',
  curexa: 'Curexa',
  curexaOrders: 'Curexa Orders',
  false: 'false',
  firstName: 'First Name',
  hasClinicianAssigned: 'HCA',
  hasOverTheCounter: 'Has OTC',
  hasPrescriptions: 'Has Prescriptions',
  items: 'Items',
  lastName: 'Last Name',
  medicationName: 'Medication Name',
  notCancelled: 'NC',
  notShipped: 'Not Shipped',
  history: 'History',
  messages: 'Messages',
  sendComment: 'Send',
  comment: 'comment',
  orderId: 'OrderId',
  order: 'Order',
  detail: 'Detail',
  overTheCounter: 'HOTC',
  paid: 'Paid',
  party: 'Party',
  patientId: 'PatientId',
  pharmacy: 'Pharmacy',
  pharmacyOrders: 'Pharmacy Orders',
  prescriber: 'Prescriber',
  prescriptions: 'HP',
  product: 'Product',
  quantity: 'Quantity',
  reason: 'Reason',
  replacementAddress: 'Replacement Address',
  replacementDetails: 'Replacement Details',
  send: 'Send',
  shipped: 'NS',
  shippingAddress: 'Shipping Address',
  shippingDetails: 'Shipping Details',
  shippingMethod: 'Shipping Method',
  sku: 'Sku',
  state: 'State',
  status: 'Status',
  statusDetails: 'Status Details',
  updated: 'Updated',
  updatedWithTime: 'Updated (with time)',
  version: 'Version',
  wasApproved: 'WA',
  wasPaid: 'WP',
  zip: 'Zip',
  replace: 'Replace',
  replaceTooltip: 'An order can only be replaced once it has been shipped',
  changeShipping: 'Change Shipping',
  cancel: 'Cancel',
  replacement: 'Replacement',
  shipping: 'Shipping',
  method: 'Method',
  trackingNumber: 'Tracking Number',
  isCancelled: 'Is Cancelled',
  isRefunded: 'Is Refunded',
  isAlreadyShipped: 'Is Already Shipped',
  missingPrescriptions: 'Missing Prescriptions',
  isNotPaid: 'Is Not Paid',
  isNotApprovedByDoctor: 'Is Not Approved By Doctor',
  hasNoClinicianAssigned: 'Has No Clinician Assigned',
  pharmacyErrors: 'Pharmacy Errors',
  resend: 'Resend',
  resendTooltip: 'An order can only be resent when it has not been shipped and is in error status',
  sftpError: 'Comment could not be sent, please retry',
  notFoundError: 'Order was not found',
  true: 'true',
};

export default PHARMACY_EN;
